import * as React from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import HomeContent from '../components/home_content'
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {

  const programs = data.allContentfulOemProgram.edges

  return (
    <Layout>
      <Seo
        title='Roadster | The #1 Commerce Platform for Car Buying'
        excludeTitleSuffix={true}
        description='Want to offer customers the ability to buy cars online? Roadster’s Express Storefront offers digital retailing capabilities — and so much more.'
      />
      <HomeContent programs={programs} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulOemProgram(sort: {fields: brand}) {
      edges {
        node {
          brand
          slug
          enrollLink
        }
      }
    }
  }
`

export default IndexPage
